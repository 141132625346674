import { FC, ReactElement } from "react";
import Tooltip from "../atoms/Tooltip";
import { FaExclamationTriangle, FaHourglassHalf, FaSpinner, FaThumbsDown, FaThumbsUp, FaTimes } from "react-icons/fa";

type AnalysisRecommendationProps = {
    code: string;
    description: string;
}

const AnalysisRecommendationIcon: FC<AnalysisRecommendationProps> = ({ code, description }) => {

    const icons: Record<string, ReactElement> = {
        "approved": <FaThumbsUp style={{ color: "cornflowerblue" }} />,
        "not-approved": <FaThumbsDown style={{ color: "rgb(242, 5, 37)" }} />,
        "pending": <FaExclamationTriangle style={{ color: "goldenrod" }} />,
        "reanalysis": <FaSpinner style={{ color: "cornflowerblue" }} />,
        "processing": <FaHourglassHalf style={{ color: "goldenrod" }} />,
        "error": <FaTimes style={{ color: "rgb(242, 5, 37)" }} />
    };

    return (
        <Tooltip content={description}>
            {icons[code]}
        </Tooltip>
    );
}

export default AnalysisRecommendationIcon;
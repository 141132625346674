import { FC } from "react";
import { IconType } from "react-icons";
import { Link } from "react-router-dom";

interface MenuItemProps {
    external?: boolean;
    Icon?: IconType | null;
    href: string;
    label: string;
    span?: string;
}

const MenuItem: FC<MenuItemProps> = ({ external, Icon, href, label, span }) => {
    let spanRender;
    if (span) {
        spanRender = <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full">{span}</span>
    }

    if (external)
        return (
            <li>
                <a href={href}
                    className="flex items-center p-2 text-sm text-slate-800 rounded-lg hover:bg-slate-500 hover:text-white group">
                    {Icon && <Icon className="w-5 h-5" />}

                    <span className="ml-3">{label}</span>

                    {spanRender}
                </a>
            </li>
        );

    return (
        <li>
            <Link to={href}
                className="flex items-center p-2 text-sm text-slate-800 rounded-lg hover:bg-slate-500 hover:text-white group">
                {Icon && <Icon className="w-5 h-5" />}

                <span className="ml-3">{label}</span>

                {spanRender}
            </Link>
        </li>
    );
};

export default MenuItem;
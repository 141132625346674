import { FC, Fragment, ReactNode, useRef, useState } from "react"
import { IoMdClose } from "react-icons/io";

type PresentationModalProps = {
	id: string;
	show: boolean;
	onClose: () => void;
	header?: string;
	children: {
		body: ReactNode;
		footer?: ReactNode;
	},
	height?: string;
}

const PresentationModal: FC<PresentationModalProps> = ({ id, show, onClose,
	header, children, height = "540px" }) => {

	const [isDragging, setIsDragging] = useState(false);
	const DialogRef = useRef<HTMLDivElement>(null);
	const [position, setPosition] = useState({ x: 0, y: 0 });
	const [startPos, setStartPos] = useState({ x: 0, y: 0 });

	const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
		setIsDragging(true);
		setStartPos({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};

	const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
		if (!isDragging) return;

		setPosition({
			x: e.clientX - startPos.x,
			y: e.clientY - startPos.y,
		});
	};

	const handleMouseUp = () => {
		setIsDragging(false);
	};

	const { body, footer } = children;

	return (show ?
		<Fragment>
			<div
				id={id}
				className="justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
				ref={DialogRef}
				style={{
					transform: `translate(${position.x}px, ${position.y}px)`,
				}}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
			>
				<div className="relative w-auto my-6 mx-auto max-w-3xl">

					<div className="border border-stale-300 rounded-lg shadow-lg shadow-slate-400 hover:shadow-slate-600 relative flex flex-col w-full bg-white outline-none focus:outline-none">

						{header && <div
							style={{ cursor: isDragging ? "grabbing" : "grab" }}
							className="flex items-start justify-between p-2 border-b border-solid border-slate-200 rounded-t">
							<h3 className="text-lg font-semibold text-gray-900">
								{header}
							</h3>
							<button
								id={`btn-${id}-close`}
								type="button"
								className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
								data-Dialog-hide={id}
								onClick={onClose}
							>
								<IoMdClose />
								<span className="sr-only">Fechar a janela</span>
							</button>
						</div>}

						<div className={`max-h-[${height}] overflow-y-auto`}>
							{body}
						</div>

						{footer && <div className="flex items-center justify-end py-2 px-6 border-t border-solid border-slate-200 rounded-b">
							{footer}
						</div>}
					</div>
				</div>
			</div>
			<div className="opacity-25 fixed inset-0 z-40 bg-black"></div>

		</Fragment> : null
	);
}

export default PresentationModal;